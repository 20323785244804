import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacidade from './privacidade';
import Home from './home';
import Solicitacao from './rotas/solicitacao/solicitacao';
import { Toaster } from "react-hot-toast";
import Validacao from './rotas/solicitacao/validacao';
import NotFound from './notFound';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacidade" element={<Privacidade />} />
          <Route path="/solicitacao" element={<Solicitacao />} />
          <Route path="/exclusao-conta/:param" element={<Validacao />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Toaster position="top-right" toastOptions={{
        error: {
          style: {
            backgroundColor: '#460402',
            color: '#fff'
          }
        },
        success: {
          style: {
            backgroundColor: '#024623',
            color: '#fff'
          }
        }
      }} />
    </>
  );
}

export default App;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import DOMPurify from 'dompurify';

const Modal = ({ onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6 relative z-50">
                <h2 className="text-2xl font-semibold mb-4">Solicitação enviada com sucesso</h2>
                <p className="mb-4">Verifique seu e-mail para fazer a confirmação</p>
                <button
                    onClick={onClose}
                    className="self-end bg-green-500 hover:bg-green-600 text-white py-2 px-10 rounded-full"
                >
                    Fechar
                </button>
            </div>
        </div>
    );
};

function Solicitacao() {

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm({
        defaultValues: {
            email: '',
        },
        mode: 'onBlur',
    });

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {

        setLoading(true);

        const purifiedData = {
            email: DOMPurify.sanitize(data.email),
        };

        try {
            const response = await fetch('https://api.recicley.com.br/sistema/usuarios/exclusao', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'RCL-API-KEY': 'nR1f2?;Idti7'
                },
                body: JSON.stringify({email: purifiedData.email}),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                setLoading(false);
                setShowModal(false);
            } else {
                setLoading(false);
                setShowModal(true);
                reset();
            }
        } catch (err) {
            setLoading(false);
            setShowModal(false);
            setError('email', { message: err?.response?.data?.message });
        }
    };

    return (
        <div className="min-h-screen bg-[#20472C] flex flex-col gap-20 items-center justify-center text-center">
            <div className="min-h-full w-full flex flex-col p-5">
                <div className="w-full h-auto flex flex-row justify-center">
                    <div className="w-full max-w-7xl h-full flex flex-col justify-between gap-5">
                        <div className="flex flex-col py-5 gap-10">
                            <div className="flex flex-col gap-5 text-left">
                                <p className="text-[25px] font-bold text-white">Solicitação para exclusão de dados</p>
                                <p className='text-white'>Recicley, desenvolvido por <span className="text-green-500">Woobe</span></p>
                                <div className="flex flex-col p-5 bg-white rounded-lg">
                                    <p className="mb-5 font-semibold">Etapas para a solicitação:</p>
                                    <p>1) Informe seu e-mail no campo indicado</p>
                                    <p>2) Uma confirmação de exclusão será enviada para seu e-mail</p>
                                    <p>3) Clique no botão de confirmação enviado no corpo do e-mail</p>
                                    <div className="flex flex-col p-5 bg-green-100 mt-5 rounded-lg">
                                        <p className="mb-5">Dados do usuário coletados pela plataforma que serão excluídos:</p>
                                        <p>- Nome completo</p>
                                        <p>- CPF ou CNPJ</p>
                                        <p>- E-mail</p>
                                        <p>- Data de nascimento</p>
                                        <p>- Dados de cartões (Número, titular, Data de vencimento)</p>
                                        <p>- Dados de endereço (Rua, Estado, Cidade, número)</p>
                                        <p className="mt-5 text-green-800">* Após a solicitação, nenhum dado é mantido na plataforma. O prazo para exclusão é de 5 dias úteis.</p>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
                                <div className="flex flex-col gap-2">
                                    <input
                                        type="email"
                                        placeholder="E-mail"
                                        {...register("email", { required: "Campo obrigatório" })}
                                        className={`w-full h-[50px] px-5 rounded-xl outline-none focus:border focus:border-blue-500 ${errors.email ? 'border border-red-500' : ''}`}
                                    />
                                    {errors.email && <p className="text-[12px] text-red-500">{errors.email.message}</p>}
                                </div>

                                <button className='px-10 bg-green-500 rounded-full text-white py-2 self-end transition-all hover:bg-green-600' type='submit' disabled={loading}>
                                    {loading ? <p>Carregando...</p> : <p>Solicitar</p>}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            {showModal && <Modal onClose={() => setShowModal(false)} />}
        </div>
    );
};

export default Solicitacao;
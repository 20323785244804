// NotFound.js
import React from 'react';

const NotFound = () => {
    return (
        <div className="min-h-screen flex flex-col gap-10 items-center justify-center bg-[#20472C] text-white">
            <h1 className="text-4xl font-bold">404 - Página não encontrada</h1>
            <p className="mt-4">Desculpa, mas a página que você está procurando não existe.</p>
        </div>
    );
};

export default NotFound;
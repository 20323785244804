import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="min-h-screen bg-[#20472C] flex flex-col gap-20 items-center justify-center text-center">
            <img src='/logo.png' className='w-[80px] object-contain'></img>
            <h1 className="text-3xl font-bold text-white">
                Nosso site está em manutenção.
            </h1>
                <Link to="/privacidade" className="pl-5 text-yellow-100 underline transition-all hover:opacity-50">
                    Política de privacidade
                </Link>
        </div>
    );
}

export default Home;

import { useState, useEffect } from "react";
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export default function Validacao() {

    const [loading, setLoading] = useState(true);
    const [sucesso, setSucesso] = useState(false);
    const [erro, setErro] = useState(false);

    const { param } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.recicley.com.br/sistema/usuarios/validar_exclusao', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'RCL-API-KEY': 'nR1f2?;Idti7'
                    },
                    body: JSON.stringify({ codigo: param }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    toast.error(errorData.message);
                    setErro(true);
                    setSucesso(false);
                } else {
                    setSucesso(true);
                    setErro(false);
                }
            } catch (err) {
                console.error('Erro:', err);
                toast.error('Ocorreu um erro na validação.');
                setErro(true);
                setSucesso(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [param]);

    return (
        <div className="min-h-screen bg-[#20472C] flex flex-col gap-20 items-center justify-center text-center">

            <div className="min-h-full w-full flex flex-col p-5">

                <div className="w-full h-auto flex flex-row justify-center">

                    <div className="w-full max-w-7xl h-full flex flex-col justify-between gap-5">

                        <div className="flex flex-col py-5 gap-5">

                            {loading ?

                                <div className="flex flex-col gap-2 rounded-lg bg-gray-300 p-5">
                                    <p className="animate-pulse">Carregando...</p>
                                </div>

                                :

                                <>
                                    {sucesso && <div className="flex flex-col gap-2 rounded-lg bg-green-300 p-5">
                                        <p className="text-[25px] font-bold">Validado com sucesso! Seus dados serão excluídos.</p>
                                    </div>}
                                    {erro && <div className="flex flex-col gap-2 rounded-lg bg-red-300 p-5">
                                        <p className="text-[25px] font-bold">Aconteceu algum erro com a validação, tente novamente.</p>
                                    </div>}
                                </>

                            }

                        </div>

                    </div >

                </div >

            </div >

        </div >
    )
}